<template>
    <div class="dashboard-container" v-loading="loading">
      <!-- 添加或编辑类别 -->
  
      <div class="app-container" style="margin-bottom: 50px">
      </div>
  
      <!-- 主体内容 -->
     
        <el-table
          :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
          :data="list"
          border
          style="width: 100%"
          ref="list"
          class="emp_table"
          v-if="list"
        >
        
          <el-table-column
            align="center"
            prop="companyName"
            label="企业名称"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            align="center"
            prop="areaName"
            label="所在地区"
            :show-overflow-tooltip="true"
          />
  
        
          <el-table-column
            align="center"
            prop="address"
            label="企业地址"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="username"
            label="管理员姓名"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="tel"
            label="联系电话"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
  
        </el-table>
        <!-- 分页 -->
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getcompany"
        />
     
    </div>
  </template>
  
  <script>
  import {
    getcompany,
   
  } from "../../request/http";
  
  export default {
    data() {
      return {
        dialog: false,
      
        title: "", //弹出框标题
        loading: true, //加载动画
        showDialog: false, //显示隐藏
        list: "", // 列表
        total: 0, //总条数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          areaId:null,
        },
       
        searchtitle: "", //搜索名称
       
      };
    },
    created() {
        this.queryParams.areaId=Number(localStorage.getItem('areaId'))
      this.getcompany();
  
    },
    methods: {
   
      //获取列表
      async getcompany() {
        let { data } = await getcompany(this.queryParams);
        
        this.list = data.data.records;
        this.total = data.data.total;
        console.log(this.list);
        this.loading = false;
      },
    //   //查询
    //   async searchgetcompany() {
    //     this.queryParams = {
    //       page: 1,
    //       pageSize: 10,
    //       companyName: this.searchtitle,
    //     };
  
    //     let { data } = await getcompany(this.queryParams);
        
    //     this.list = data.data.records;
    //     this.total = data.data.total;
        
    //   },
     
  
      
  
   
  
     
    
    },
  };
  </script>
  
  <style>
  </style>